import { useQuery } from 'react-query';

const baseurl = 'https://api.holdstation.com';

interface PriceResponse {
  price_change_percentage: number;
  prices: [number, number][];
}

async function fetchPrice(props: any) {
  const activeTab = props.queryKey[1];
  const token = props.queryKey[2];

  const response = await fetch(`${baseurl}/api/coins/${token}/market-chart?currency=usd&interval=&days=${activeTab}`);

  const data = await response.json();

  return data;
}

const array_token = ['usd coin (native)', 'tether usd', 'usd coin (ethereum)'];
function convertToken(tokenName: string) {
  if (array_token.includes(tokenName)) {
    return 'usd';
  }

  return tokenName;
}

export function useGetPriceData(activeTab: string, token: string) {
  const { data, error } = useQuery<PriceResponse>(['ownPrice', activeTab, convertToken(token)], fetchPrice, {
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  return {
    data,
  };
}

export const chart_time = [
  {
    title: '1H',
    value: '1/24',
  },
  {
    title: '1D',
    value: '1',
  },
  {
    title: '7D',
    value: '7',
  },
  {
    title: '1M',
    value: '30',
  },
  {
    title: '1Y',
    value: '365',
  },
];
