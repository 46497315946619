import { ChainId, Currency, CurrencyAmount, Fraction, Percent, Price, Token, TradeType } from '@uniswap/sdk-core'
import JSBI from 'jsbi'
import invariant from 'tiny-invariant'
import { BIPS_BASE } from '../constants/misc'
import { TradeFillType } from '../state/routing/types'

const NATIVE_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'
const ZERO = JSBI.BigInt(0)
const ONE = JSBI.BigInt(1)

interface EncodeRouterTradeResult {
  router: string
  data: string
  value?: string
}

type RouteHop = {
  from: string
  to: string
  amount: string
  pool: string
  protocol: string
} & Record<string, unknown>

interface Route {
  amount: string
  swaps: RouteHop[]
}

type Routes = Route[] // multi path

interface SwapFee {
  percent: Percent
  usd: number
}

/**
 * Represents a trade executed against a list of pairs.
 * Does not account for slippage, i.e. trades that front run this trade and move the price.
 */
export class SmartRouterTrade<
  TInput extends Currency = Currency,
  TOutput extends Currency = Currency,
  TTradeType extends TradeType = TradeType
> {
  /**
   * The type of the trade, either exact in or exact out.
   */
  public readonly tradeType: TTradeType
  /**
   * The input amount for the trade assuming no slippage.
   */
  public readonly inputAmount: CurrencyAmount<TInput>
  /**
   * The output amount for the trade assuming no slippage.
   */
  public readonly outputAmount: CurrencyAmount<TOutput>
  /**
   */
  public readonly routes: Routes
  /**
   * The price expressed in terms of output amount/input amount.
   */
  public readonly executionPrice: Price<TInput, TOutput>

  public readonly searchParams: URLSearchParams
  public readonly amountInUsd: number
  public readonly amountOutUsd: number
  public readonly swapFee?: SwapFee

  public readonly fillType: TradeFillType = TradeFillType.Classic

  public constructor(
    searchParams: URLSearchParams,
    tradeType: TTradeType,
    inputAmount: CurrencyAmount<TInput>,
    outputAmount: CurrencyAmount<TOutput>,
    amountInUsd: number,
    amountOutUsd: number,
    routes: Routes,
    swapFee: SwapFee | undefined
  ) {
    this.searchParams = searchParams
    this.tradeType = tradeType
    this.inputAmount = inputAmount
    this.outputAmount = outputAmount
    this.amountInUsd = amountInUsd
    this.amountOutUsd = amountOutUsd
    this.executionPrice = new Price(
      inputAmount.currency,
      outputAmount.currency,
      inputAmount.quotient,
      outputAmount.quotient
    )
    this.routes = routes
    this.swapFee = swapFee
  }

  /**
   * Get the minimum amount that must be received from this trade for the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  public minimumAmountOut(slippageTolerance: Percent): CurrencyAmount<TOutput> {
    invariant(!slippageTolerance.lessThan(ZERO), 'SLIPPAGE_TOLERANCE')
    if (this.tradeType === TradeType.EXACT_OUTPUT) {
      return this.outputAmount
    }
    const slippageAdjustedAmountOut = new Fraction(ONE)
      .add(slippageTolerance)
      .invert()
      .multiply(this.outputAmount.quotient).quotient
    return CurrencyAmount.fromRawAmount(this.outputAmount.currency, slippageAdjustedAmountOut)
  }

  /**
   * Get the maximum amount in that can be spent via this trade for the given slippage tolerance
   * @param slippageTolerance tolerance of unfavorable slippage from the execution price of this trade
   */
  public maximumAmountIn(slippageTolerance: Percent): CurrencyAmount<TInput> {
    invariant(!slippageTolerance.lessThan(ZERO), 'SLIPPAGE_TOLERANCE')
    if (this.tradeType === TradeType.EXACT_INPUT) {
      return this.inputAmount
    }
    const slippageAdjustedAmountIn = new Fraction(ONE)
      .add(slippageTolerance)
      .multiply(this.inputAmount.quotient).quotient
    return CurrencyAmount.fromRawAmount(this.inputAmount.currency, slippageAdjustedAmountIn)
  }

  /**
   * Return the execution price after accounting for slippage tolerance
   * @param slippageTolerance the allowed tolerated slippage
   * @returns The execution price
   */
  public worstExecutionPrice(slippageTolerance: Percent): Price<TInput, TOutput> {
    return new Price(
      this.inputAmount.currency,
      this.outputAmount.currency,
      this.maximumAmountIn(slippageTolerance).quotient,
      this.minimumAmountOut(slippageTolerance).quotient
    )
  }

  /**
   * Get the input currency USD price
   */
  public get inputUsdPrice(): number | undefined {
    return this.amountInUsd && this.inputAmount.greaterThan(0)
      ? this.amountInUsd / Number(this.inputAmount.toExact())
      : undefined
  }

  /**
   * Get the output currency USD price
   */
  public get outputUsdPrice(): number | undefined {
    return this.amountOutUsd && this.outputAmount.greaterThan(0)
      ? this.amountOutUsd / Number(this.outputAmount.toExact())
      : undefined
  }

  // public get priceImpact(): number | undefined {
  //   return this.amountInUsd && this.amountOutUsd ? (this.amountInUsd - this.amountOutUsd) / this.amountInUsd : 0
  // }

  /**
   * The cached result of the price impact computation
   * @private
   */
  private _priceImpact: Percent | undefined

  /**
   * Returns the percent difference between the output USD and the input USD
   */
  public get priceImpact(): Percent {
    if (this._priceImpact) {
      return this._priceImpact
    }

    const priceImpact =
      this.amountInUsd && this.amountOutUsd ? (this.amountInUsd - this.amountOutUsd) / this.amountInUsd : 0
    this._priceImpact = new Percent(Math.floor(priceImpact * 10000), 10000)

    return this._priceImpact
  }

  /**
   * Given a list of pairs, and a fixed amount in, returns the top `maxNumResults` trades that go from an input token
   * amount to an output token, making at most `maxHops` hops.
   * Note this does not consider aggregation, as routes are linear. It's possible a better route exists by splitting
   * the amount in among multiple routes.
   * @param routerQuoteURL the api url to get the best trade
   * @param currencyAmountIn exact amount of input currency to spend
   * @param currencyOut the desired currency out
   * @param options
   */
  public static async bestTradeExactIn<TInput extends Currency = Currency, TOutput extends Currency = Currency>(
    routerQuoteURL: string,
    currencyAmountIn: CurrencyAmount<TInput>,
    currencyOut: TOutput,
    options?: {
      protocols?: string[]
    }
  ): Promise<SmartRouterTrade | null> {
    const chainId: ChainId | undefined =
      currencyAmountIn.currency.chainId || (currencyOut instanceof Token ? currencyOut.chainId : undefined)
    invariant(chainId !== undefined, 'CHAIN_ID')

    const fromToken = currencyAmountIn.currency.isNative ? NATIVE_ADDRESS : currencyAmountIn.currency.address
    const toToken = currencyOut.isNative ? NATIVE_ADDRESS : currencyOut.address
    if (fromToken && toToken) {
      const initSearchParams: Record<string, string> = {
        src: fromToken.toString(),
        dst: toToken.toString(),
        amount: currencyAmountIn.quotient.toString(),
      }
      if (options?.protocols?.length) {
        initSearchParams.protocols = options.protocols.join(',')
      }
      const searchParams = new URLSearchParams(initSearchParams)

      const response = await fetch(`${routerQuoteURL}?${searchParams}`)
      const result = (await response.json()) || ({} as any)
      if (
        !result ||
        !result.amount ||
        result.amount === '0' ||
        !result.toAmount ||
        result.toAmount === '0' ||
        !result.protocols?.length
      ) {
        throw new Error('NO_ROUTE')
      }

      const toCurrencyAmount = <TCurrency extends Currency>(
        value: string,
        currency: TCurrency
      ): CurrencyAmount<TCurrency> => {
        return CurrencyAmount.fromRawAmount(currency, value)
      }

      const inputAmount = toCurrencyAmount<TInput>(result.amountIn, currencyAmountIn.currency)
      const outputAmount = toCurrencyAmount<TOutput>(result.amountOut, currencyOut)

      const { amountUsd: amountInUsd, toAmountUsd: amountOutUsd, feePercent, feeUsd } = result

      const swapFee: SwapFee | undefined = feePercent
        ? { percent: new Percent(parseInt((feePercent * 100).toString()), BIPS_BASE), usd: feeUsd }
        : undefined

      return new SmartRouterTrade(
        searchParams,
        TradeType.EXACT_INPUT,
        inputAmount,
        outputAmount,
        amountInUsd,
        amountOutUsd,
        result.protocols,
        swapFee
      )
    }
    return null
  }

  public static async encodeRouterTrade(
    routerEncodeURL: string,
    trade: SmartRouterTrade,
    receiver: string,
    options?: {
      slippageTolerance?: number
      referrer?: string
      deadline?: number
    }
  ): Promise<EncodeRouterTradeResult> {
    if (!routerEncodeURL) {
      throw new Error('missing router endpoint')
    }
    const initSearchParams: Record<string, string> = {
      ...Object.fromEntries(trade.searchParams),
      receiver,
    }
    if (options?.slippageTolerance) {
      initSearchParams.slippage = (options.slippageTolerance / 100).toString()
    }
    if (options?.referrer) {
      initSearchParams.ref = options.referrer
    }
    if (options?.deadline) {
      initSearchParams.deadline = options.deadline.toString()
    }
    const searchParams = new URLSearchParams(initSearchParams)
    const response = await fetch(`${routerEncodeURL}/swap?${searchParams}`)
    const result = (await response.json())?.tx as any
    if (!result?.data) {
      throw new Error('ENCODE_FAILED')
    }
    const value = trade.inputAmount.currency.isNative ? trade.inputAmount.quotient : undefined
    return { ...result, value } as EncodeRouterTradeResult
  }
}
