import styled from 'styled-components'
import { Settings } from 'components/Icons/Settings'
import { ReactNode, useCallback, useState } from 'react'
import { Dispatch, SetStateAction } from 'react'
import { Close } from 'components/Icon/Icon'
import { ModalCenter } from 'components/Modal/ModalV2'
import { ButtonPrimaryV2 } from 'components/Button/ButtonPrimaryV2'
import { Input } from 'components/Settings/Input'
import { useUserTransactionTTL } from 'state/user/hooks'
import { DEFAULT_DEADLINE_FROM_NOW } from 'constants/misc'
import ms from 'ms'
import { Trans } from '@lingui/macro'
import QuestionHelper from 'components/QuestionHelper'
import { QuestionHelperV2 } from 'components/QuestionHelper/QuestionHelperV2'

const Icon = styled(Settings)`
  height: 20px;
  width: 20px;
  > * {
    fill: ${({ theme }) => theme.neutral2};
  }
`

const THREE_DAYS_IN_SECONDS = ms(`3d`) / 1000
export const NUMBERS_ONLY = /^[0-9\b]+$/

export function ModalSetting(props: { isVisible: boolean; setIsVisible: Dispatch<SetStateAction<boolean>> }) {
  const { isVisible, setIsVisible } = props

  const [deadline, setDeadline] = useUserTransactionTTL()
  const defaultInputValue = deadline && deadline !== DEFAULT_DEADLINE_FROM_NOW ? (deadline / 60).toString() : ''

  const [deadlineInput, setDeadlineInput] = useState(defaultInputValue)

  function parseCustomDeadline(value: string) {
    // Do not allow non-numerical characters in the input field
    if (value.length > 0 && !NUMBERS_ONLY.test(value)) {
      return
    }

    setDeadlineInput(value)
  }

  const saveSetting = useCallback(() => {
    // If the input is empty, set the deadline to the default
    if (deadlineInput.length === 0) {
      setDeadline(DEFAULT_DEADLINE_FROM_NOW)
      return setIsVisible(false)
    }

    // Parse user input and set the deadline if valid, error otherwise
    try {
      const parsed: number = Number.parseInt(deadlineInput) * 60
      if (parsed === 0 || parsed > THREE_DAYS_IN_SECONDS) {
      } else {
        setDeadline(parsed)
      }
    } catch (error) {
    } finally {
      setIsVisible(false)
    }
  }, [deadlineInput])

  return (
    <ModalCenter isVisible={isVisible} setIsVisible={setIsVisible} className="bg-[#121212]">
      <div>
        <div className="flex flex-1 items-center justify-between mb-[24px]">
          <h4 className="text-[20px] leading-[28px] tracking-[-0.4px] text-white">Settings</h4>

          <button onClick={() => setIsVisible((v) => !v)}>
            <Close />
          </button>
        </div>

        <div className="mb-[12px] flex items-center gap-[4px]">
          <p className="text-[14px] leading-[20px] tracking-[-0.32px] text-[#666]">Transaction deadline</p>

          <QuestionHelperV2
            placement="bottom"
            text={'Your transaction will revert if it is pending for more than this period of time.'}
          />
        </div>

        <div className="flex items-center gap-[12px] mb-[24px] rounded-full border border-[#1C1C1C]">
          <span className="text-[#808080] text-[16px] leading-[24px] tracking-[-0.32px] pl-[24px]">Minutes</span>

          <Input
            style={{
              width: '100%',
              fontSize: '16px',
              lineHeight: '24px',
              textAlign: 'right',
              height: '48px',
            }}
            className="outline-none bg-transparent text-white text-[16px] leading-[24px] tracking-[-0.32px] pr-[24px]"
            data-testid="deadline-input"
            placeholder={(DEFAULT_DEADLINE_FROM_NOW / 60).toString()}
            value={deadlineInput}
            onChange={(e) => parseCustomDeadline(e.target.value)}
          />
        </div>

        <ButtonPrimaryV2 onClick={saveSetting}>
          <span>Save settings</span>
        </ButtonPrimaryV2>
      </div>
    </ModalCenter>
  )
}

export function SettingHeader({ children }: { children?: ReactNode }) {
  const [isVisible, setIsVisible] = useState(false)

  return (
    <>
      {children ? (
        <button onClick={() => setIsVisible(true)}>{children}</button>
      ) : (
        <button onClick={() => setIsVisible(true)} className="px-[20px] py-[10px] rounded-full bg-[#121212]">
          <Icon />
        </button>
      )}

      {isVisible && <ModalSetting isVisible={isVisible} setIsVisible={setIsVisible} />}
    </>
  )
}
