import syncImg from 'assets/svg/synergy.svg'
import { useEstimate } from './data'
import { useMemo } from 'react'
import BigNumber from 'bignumber.js'
import { formatDisplay } from '../Chart/format-balance'
import { useSwapContext } from 'state/swap/hooks'
import { Field } from 'components/swap/constants'
import { SwapRouting } from 'components/swap/SwapRouting/SwapRouting'
import clsx from 'clsx'

export const NATIVE_ADDRESS = '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee'

export function SwapEstimate({ showRouting, className }: { showRouting?: boolean; className?: string }) {
  const {
    derivedSwapInfo: {
      currencies,
      trade: { trade },
    },
    swapState: { typedValue },
  } = useSwapContext()

  const token_in = useMemo(() => {
    const input = currencies?.[Field.INPUT]

    if (!input) {
      return {
        address: '',
        decimals: 18,
      }
    }

    if (input.isNative) {
      return {
        address: NATIVE_ADDRESS,
        decimals: 18,
      }
    }

    return {
      address: input.address,
      decimals: input?.decimals || 18,
    }
  }, [currencies])

  const token_out = useMemo(() => {
    const output = currencies?.[Field.OUTPUT]

    if (!output) {
      return {
        address: '',
        decimals: 18,
      }
    }

    if (output.isNative) {
      return {
        address: NATIVE_ADDRESS,
        decimals: 18,
      }
    }

    return {
      address: output.address,
      decimals: output?.decimals || 18,
    }
  }, [currencies])

  const { data } = useEstimate(token_in, token_out, typedValue, currencies?.[Field.OUTPUT])

  const estimate = useMemo(() => {
    if (!data) {
      return '--'
    }

    const res = new BigNumber(data.estimate).toNumber()
    return formatDisplay(res, { decimalToShow: 0 })
  }, [data])

  return (
    <div
      className={clsx(className, 'px-[24px] py-[16px] rounded-[16px] flex flex-col gap-[12px]', {
        'bg-[#0D0D0D]': showRouting,
      })}
    >
      <div className="flex items-center justify-between">
        <p className="text-sm text-[#808080]">Estimated Point Earned</p>

        <div className="flex items-center gap-1">
          <img src={syncImg} className="w-[20px] aspect-square" alt="syncImg" width={28} height={28} loading="lazy" />

          <p className="text-base text-white">{estimate}</p>
        </div>
      </div>

      {showRouting && trade && <SwapRouting trade={trade as any} />}
    </div>
  )
}
