import { Close } from 'components/Icon/Icon'
import { motion } from 'framer-motion'
import { Dispatch, ReactElement, SetStateAction, useRef } from 'react'

const fadeVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

interface ModalCenterProps {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
  children: ReactElement
  isHeader?: boolean
  title?: string
  className?: string
  clickOutside?: () => void
  headerClassname?: string
}

export function ModalCenter(props: ModalCenterProps) {
  const {
    isVisible,
    setIsVisible,
    children,
    isHeader = false,
    title,
    className = '',
    clickOutside,
    headerClassname,
  } = props
  const modalRef = useRef<HTMLDivElement>(null)

  return (
    <>
      {isVisible && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={fadeVariants}
          transition={{ duration: 0.2 }}
          className="fixed inset-0 z-20 flex items-center justify-center"
        >
          <div
            className="fixed bg-rgba080 inset-0 z-19"
            onClick={() => {
              if (clickOutside) {
                clickOutside()
              }
              setIsVisible((v) => !v)
            }}
          />

          <div
            ref={modalRef}
            className={`z-20 p-6 rounded-2xl bg-black6 w-full max-w-[400px] transition-all ${className}`}
          >
            {isHeader && (
              <div className="flex items-center justify-between mb-4">
                <div className="w-8 h-8" />

                <h2 className={`text-[24px] leading-8 font-medium ${headerClassname}`}>{title}</h2>

                <button
                  className="w-8 h-8 flex justify-center items-center opacity-80 hover:opacity-100 transition-all"
                  onClick={() => setIsVisible((v) => !v)}
                >
                  <Close />
                </button>
              </div>
            )}

            {children}
          </div>
        </motion.div>
      )}
    </>
  )
}
