import { ChainId } from '@uniswap/sdk-core'

export const SWAP_ROUTER_GATEWAY_DNS =
  (process.env.REACT_APP_UNISWAP_GATEWAY_DNS as string) || 'https://swap.hold.so/api'

export const SWAP_ROUTER_ADDRESSES: { [chainId: number]: string } = {
  [ChainId.ZKSYNC]: '0xD1f1bA4BF2aDe4F47472D0B73ba0f5DC30E225DF',
}

// enum EnumDexes {
//   HoldstationSwap = 'holdstationswap',
//   PancakeV2 = 'pancakev2',
//   PancakeV3 = 'pancakev3',
//   KoiFinance = 'koifinance',
//   zkSwap = 'zkswap',
//   Iziswap = 'iziswap',
//   SpaceFi = 'spacefi',
//   Velocore = 'velocore',
//   veSync = 'vesync',
//   SyncSwap = 'syncswap',
//   eZKalibur = 'ezkalibur',
// }
//
// interface DexType {
//   id: string
//   name: string
//   icon: string
//   chainIds: ChainId[]
//   active?: boolean
// }
//
// type DexesConfig = { [id in EnumDexes]: Omit<DexType, 'id'> }
//
// export const dexesConfig: DexesConfig = {
//   [EnumDexes.HoldstationSwap]: {
//     name: 'Holdstation',
//     icon: 'https://holdstation.com/favicon.ico',
//     chainIds: [ChainId.ZKSYNC],
//   },
//   [EnumDexes.PancakeV2]: {
//     name: 'PancakeSwap V2',
//     icon: 'https://assets.coingecko.com/markets/images/687/standard/pancakeswap.jpeg',
//     chainIds: [ChainId.ZKSYNC],
//   },
//   [EnumDexes.PancakeV3]: {
//     name: 'PancakeSwap V3',
//     icon: 'https://assets.coingecko.com/markets/images/687/standard/pancakeswap.jpeg',
//     chainIds: [ChainId.ZKSYNC],
//   },
//   [EnumDexes.KoiFinance]: {
//     name: 'Koi Finance',
//     icon: 'https://assets.coingecko.com/markets/images/1104/standard/koi_finance.jpeg',
//     chainIds: [ChainId.ZKSYNC],
//   },
//   [EnumDexes.zkSwap]: {
//     name: 'zkSwap',
//     icon: 'https://assets.coingecko.com/coins/images/30582/standard/zkswap.jpeg',
//     chainIds: [ChainId.ZKSYNC],
//   },
//   [EnumDexes.Iziswap]: {
//     name: 'iZUMi.finance',
//     icon: 'https://assets.coingecko.com/coins/images/21791/standard/izumi-logo-symbol.png',
//     chainIds: [ChainId.ZKSYNC],
//   },
//   [EnumDexes.SpaceFi]: {
//     name: 'SpaceFi',
//     icon: 'https://assets.coingecko.com/markets/images/1073/standard/SpaceFi.png',
//     chainIds: [ChainId.ZKSYNC],
//   },
//   [EnumDexes.Velocore]: {
//     name: 'Velocore',
//     icon: 'https://assets.coingecko.com/markets/images/1116/standard/velocore.png',
//     chainIds: [ChainId.ZKSYNC],
//   },
//   [EnumDexes.veSync]: {
//     name: 'veSync',
//     icon: 'https://assets.coingecko.com/coins/images/30611/standard/veSync-Logo-PNG-200X200.png',
//     chainIds: [ChainId.ZKSYNC],
//   },
//   [EnumDexes.SyncSwap]: {
//     name: 'SyncSwap',
//     icon: 'https://assets.coingecko.com/markets/images/1113/standard/kg4-p_V7_400x400.jpg',
//     chainIds: [ChainId.ZKSYNC],
//   },
//   [EnumDexes.eZKalibur]: {
//     name: 'eZKalibur',
//     icon: 'https://assets.coingecko.com/markets/images/1190/standard/ezkalibur.jpg',
//     chainIds: [ChainId.ZKSYNC],
//   },
// }
