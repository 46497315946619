import holdstationwallet from 'assets/images/holdstation-walllet.png'
import qr from 'assets/svg/qr.svg'
import close from 'assets/svg/close.svg'
import { useState } from 'react'
import { ModalCenter } from 'components/Modal/ModalV2'
import apple from 'assets/svg/apple.svg'
import chPlay from 'assets/svg/ch-play.svg'
import { Link } from 'react-router-dom'
import qrLink from 'assets/images/qr-code.png'
import { walletConnectV2Connection } from 'connection'
import { useActivationState } from 'connection/activate'
import { useWeb3React } from '@web3-react/core'
import { useToggleAccountDrawer } from 'components/AccountDrawer/MiniPortfolio/hooks'

// b83a346877b71c02b8531f53485ce12bc00033eabcc1213ca3329cbc744813a5

export function HoldstationWallet() {
  const { activationState, tryActivation } = useActivationState()
  const { chainId } = useWeb3React()
  const toggleAccountDrawer = useToggleAccountDrawer()

  const connection = walletConnectV2Connection

  function toggleModal() {
    tryActivation(connection, toggleAccountDrawer, chainId)
  }

  const [isVisible, setIsVisible] = useState(false)
  const [download, setDownload] = useState(false)

  function closeModal() {
    setDownload(false)
    setIsVisible((v) => !v)
  }

  return (
    <>
      <p className="text-[#4C4C4C] text-sm">Recommend</p>

      <div
        onClick={toggleModal}
        className="flex items-center justify-between gap-[12px] cursor-pointer p-[16px] rounded-[12px] bg-[#1C1C1C]"
      >
        <div className="w-[40px] h-[40px]">
          <img src={holdstationwallet} alt="holdstation wallet icon" width={160} height={160} loading="lazy" />
        </div>

        <div className="flex-1 flex flex-col">
          <p className="text-base">Holdstation wallet</p>

          <p className="text-xs text-[#808080]">Scan QR code to connect</p>
        </div>

        <img src={qr} alt="holdstation wallet icon " width={24} height={24} loading="lazy" />
      </div>

      {isVisible && (
        <ModalCenter
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          className="max-w-[400px] w-full bg-[#121212] rounded-[16px] p-[20px]"
        >
          <div className="w-full h-full">
            <div className="flex items-center justify-between mb-[24px]">
              <p className="text-xl">Scan with Holdstation wallet</p>

              <button onClick={closeModal}>
                <img src={close} alt="icon close" width={24} height={24} loading="lazy" />
              </button>
            </div>

            {!download && (
              <div>
                <div className="w-full h-[360px] bg-white rounded-[16px]"></div>

                <div className="w-full h-[1px] bg-[#292929] my-[24px]" />

                <div className="flex items-center gap-[24px]">
                  <div className="flex flex-col gap-[4px]">
                    <p className="text-sm font-medium tracking-[-0.28px]">Don’t have a Holdstation wallet</p>

                    <p className="text-sm text-[#808080]">Safely store and swap tokens with the Uniswap</p>
                  </div>

                  <button
                    onClick={() => {
                      setDownload(true)
                    }}
                    className="px-[24px] text-sm tracking-[-0.28px] bg-white text-black font-medium rounded-full h-[48px] whitespace-nowrap flex items-center"
                  >
                    Download App
                  </button>
                </div>
              </div>
            )}

            {download && (
              <div className="flex items-center gap-[40px]">
                <div className="flex-1 flex flex-col gap-[12px]">
                  <Link
                    className="rounded-full bg-[#1C1C1C] flex items-center gap-2 px-[24px] py-[22px]"
                    to="https://apps.apple.com/us/app/holdstation-web3-wallet/id6444925618"
                    target="_blank"
                  >
                    <img src={apple} alt="apple icon" width={21} height={20} loading="lazy" />

                    <p className="text-sm">App Store</p>
                  </Link>

                  <Link
                    to="https://play.google.com/store/apps/details?id=io.holdstation"
                    target="_blank"
                    className="rounded-full bg-[#1C1C1C] flex items-center gap-2 px-[24px] py-[22px]"
                  >
                    <img src={chPlay} alt="apple icon" width={21} height={20} loading="lazy" />

                    <p className="text-sm">CH Play</p>
                  </Link>
                </div>

                <div className="w-[160px] aspect-square rounded-[16px] overflow-hidden p-[8px] bg-white">
                  <img
                    src={qrLink}
                    alt="qrLink icon"
                    width={300}
                    height={300}
                    loading="lazy"
                    className="w-full h-full"
                  />
                </div>
              </div>
            )}
          </div>
        </ModalCenter>
      )}
    </>
  )
}
