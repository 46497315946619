import { Trace, TraceEvent } from 'analytics'
import { PoolHeader } from './pool-header'
import { InterfacePageName } from '@uniswap/analytics-events'
import { Outlet } from 'react-router-dom'

export function PoolV3() {
  return (
    <Trace page={InterfacePageName.POOL_PAGE} shouldLogImpression>
      <section className="w-full max-w-[1232px] px-[16px] md:mt-[61px]">
        <PoolHeader />

        <Outlet />
      </section>
    </Trace>
  )
}
