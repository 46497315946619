import { useQuery } from 'react-query'

interface PointLpResponse {
  estimate: string
}

async function fetchEstimate({ queryKey }: { queryKey: any }) {
  const pool = queryKey[1]
  const token0 = queryKey[2]
  const amount0 = queryKey[3]
  const token1 = queryKey[4]
  const amount1 = queryKey[5]

  if (!amount0 && !amount1) {
    throw new Error('Amount is not available')
  }

  const url = `https://stats-api.hold.so/v1/points/estimate?action=add_lp&pool=${pool}&token0=${token0}&amount0=${amount0}&token1=${token1}&amount1=${amount1}`

  const response = await fetch(url)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  return response.json()
}

export function useEstimate(
  callData: {
    pool: string
    token0: string
    amount0: string
    token1: string
    amount1: string
  },
  poolAddress: string
) {
  const { token0, amount0, token1, amount1 } = callData

  const data = useQuery<PointLpResponse>({
    queryKey: ['estimate-point-lp', poolAddress, token0, amount0, token1, amount1],
    queryFn: fetchEstimate,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 30000,
    refetchInterval: 30000,
  })

  return data
}
