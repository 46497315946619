// a list of tokens by chain
import { ChainId, Currency, Token } from '@uniswap/sdk-core'

import {
  HOLD_ZKSYNC,
  KZ_ZKSYNC,
  USDCe_ZKSYNC,
  USDT_ZKSYNC,
  WBTC_ZKSYNC,
  WRAPPED_NATIVE_CURRENCY,
  ZK_ZKSYNC,
  nativeOnChain,
} from './tokens'

type ChainTokenList = {
  readonly [chainId: number]: Token[]
}

type ChainCurrencyList = {
  readonly [chainId: number]: Currency[]
}

const WRAPPED_NATIVE_CURRENCIES_ONLY: ChainTokenList = Object.fromEntries(
  Object.entries(WRAPPED_NATIVE_CURRENCY)
    .map(([key, value]) => [key, [value]])
    .filter(Boolean)
)

/**
 * Shows up in the currency select for swap and add liquidity
 */
export const COMMON_BASES: ChainCurrencyList = {
  [ChainId.ZKSYNC]: [
    nativeOnChain(ChainId.ZKSYNC),
    USDCe_ZKSYNC,
    USDT_ZKSYNC,
    WBTC_ZKSYNC,
    // WRAPPED_NATIVE_CURRENCY[ChainId.ZKSYNC] as Token,
    HOLD_ZKSYNC,
    ZK_ZKSYNC,
    KZ_ZKSYNC,
  ],
}

// used to construct the list of all pairs we consider by default in the frontend
export const BASES_TO_TRACK_LIQUIDITY_FOR: ChainTokenList = {
  ...WRAPPED_NATIVE_CURRENCIES_ONLY,
}
export const PINNED_PAIRS: { readonly [chainId: number]: [Token, Token][] } = {}
