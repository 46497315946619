import { XAxis, YAxis, Tooltip, AreaChart, Area, ResponsiveContainer } from 'recharts';
import { chart_time, useGetPriceData } from './price-data';
import { memo, useMemo, useState } from 'react';
import { formatDisplay } from './format-balance';
import clsx from 'clsx';
import CurrencyLogo from 'components/Logo/CurrencyLogo';
import { Currency } from '@uniswap/sdk-core';

function ChartOutputComponent({ outputCurrency }: { outputCurrency: Currency }) {
  const [activeTab, setActiveTab] = useState('1');

  const { data } = useGetPriceData(activeTab, outputCurrency?.name?.toLowerCase() || '');
  const data_price = useMemo(() => {
    if (!data || !outputCurrency) {
      return [];
    }

    return data.prices.map((price, idx) => ({
      time: price[0],
      price: price[1],
    }));
  }, [data, outputCurrency]);

  const value = useMemo(() => {
    if (!data || !outputCurrency) {
      return {
        max: 0,
        min: 0,
      };
    }

    const array = data.prices.map((price) => price[1]);

    return {
      max: Math.max(...array),
      min: Math.min(...array),
    };
  }, [data, outputCurrency]);

  if (!outputCurrency) {
    return null;
  }

  return (
    <div className="flex-1">
      <div className="flex items-center gap-[8px] mb-[16px]">
        <div className="w-[28px] h-[28px] rounded-full bg-white">
          <CurrencyLogo currency={outputCurrency} size="28px" />
        </div>

        <p className="text-[20px] leading-[28px] tracking-[-0.4px] text-white">{outputCurrency?.name}</p>

        <p className="text-[20px] leading-[28px] tracking-[-0.4px] text-[#808080]">{outputCurrency?.symbol}</p>
      </div>

      <div className="flex items-center justify-between mb-[24px]">
        <p className="text-[24px] leading-[32px] tracking-[-0.48px] text-white">
          ${formatDisplay(data_price?.[data_price.length - 1]?.price || 0, { decimalToShow: 2 })}
        </p>

        <div className="flex items-center gap-[8px]">
          {chart_time.map((item, idx) => {
            return (
              <button
                onClick={() => setActiveTab(item.value)}
                className={clsx(
                  'text-[14px] leading-[20px] tracking-[-0.32px] text-[#4C4C4C] px-[12px] py-[8px] rounded-full',
                  { 'bg-[#171717]': item.value === activeTab }
                )}
                key={idx}
              >
                {item.title}
              </button>
            );
          })}
        </div>
      </div>

      <ResponsiveContainer className="100%" height={120}>
        <AreaChart data={data_price}>
          <XAxis
            axisLine={false}
            tickLine={false}
            tickMargin={12}
            tickFormatter={(value) => {
              const formatter = new Intl.DateTimeFormat('en-US', {
                hour: '2-digit',
                minute: '2-digit',
                timeZone: 'UTC',
              });
              const formattedDate = formatter.format(new Date(value));

              return `${formattedDate}`;
            }}
            dataKey="time"
            className="text-[12px] text-[#4C4C4C] font-semibold"
          />
          <YAxis
            domain={[value.min, value.max]}
            tickFormatter={(value) => {
              return `$${formatDisplay(value, { decimalToShow: 2 })}`;
            }}
            axisLine={false}
            tickMargin={4}
            tickLine={false}
            orientation="right"
            className="text-[12px] text-[#4C4C4C]"
          />

          <Tooltip
            content={({ payload }) => {
              const price = payload?.at(0)?.payload?.price ?? 0;

              return <h4>${formatDisplay(price, { decimalToShow: 2 })}</h4>;
            }}
          />

          <Area
            type="monotone"
            dataKey="price"
            stroke={Number(data?.price_change_percentage || 0) >= 0 ? '#00C076' : '#F55050'}
            fillOpacity={1}
            fill={Number(data?.price_change_percentage || 0) >= 0 ? '#00C07626' : '#F5505026'}
          />
        </AreaChart>
      </ResponsiveContainer>
    </div>
  );
}
export const ChartOutput = memo(ChartOutputComponent);
