import { ComponentProps } from 'react'

export const Power = (props: ComponentProps<'svg'>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" {...props}>
    <g clipPath="url(#clip0_7346_3817)">
      <path
        d="M13.4546 3.28768C13.577 3.10374 13.7674 2.97592 13.984 2.93232C14.2006 2.88872 14.4256 2.93292 14.6096 3.05518C15.7556 3.8151 16.6956 4.84699 17.3456 6.05872C17.9956 7.27045 18.3354 8.62429 18.3346 9.99935C18.3346 14.6018 14.6038 18.3327 10.0013 18.3327C5.3988 18.3327 1.66797 14.6018 1.66797 9.99935C1.66722 8.62429 2.00701 7.27045 2.65701 6.05872C3.307 4.84699 4.24697 3.8151 5.39297 3.05518C5.57707 2.93285 5.80223 2.88867 6.01892 2.93235C6.2356 2.97603 6.42606 3.10399 6.54839 3.2881C6.67072 3.4722 6.7149 3.69736 6.67122 3.91405C6.62754 4.13073 6.49957 4.32118 6.31547 4.44352C5.12217 5.23528 4.21554 6.39025 3.72982 7.73744C3.24409 9.08463 3.20508 10.5524 3.61858 11.9235C4.03207 13.2946 4.87607 14.4961 6.02564 15.3501C7.17521 16.2041 8.56922 16.6653 10.0013 16.6653C11.4334 16.6653 12.8274 16.2041 13.977 15.3501C15.1265 14.4961 15.9705 13.2946 16.384 11.9235C16.7975 10.5524 16.7585 9.08463 16.2728 7.73744C15.7871 6.39025 14.8804 5.23528 13.6871 4.44352C13.5959 4.38298 13.5174 4.30506 13.4563 4.21419C13.3952 4.12333 13.3526 4.02131 13.3309 3.91396C13.3092 3.80662 13.3089 3.69605 13.33 3.58859C13.3511 3.48113 13.394 3.37888 13.4546 3.28768ZM10.0013 1.66602C10.2054 1.66604 10.4024 1.74098 10.5549 1.87661C10.7075 2.01224 10.8049 2.19914 10.8288 2.40185L10.8346 2.49935V10.8327C10.8344 11.0451 10.7531 11.2494 10.6073 11.4038C10.4615 11.5583 10.2622 11.6512 10.0501 11.6637C9.83811 11.6761 9.62932 11.6071 9.46644 11.4708C9.30357 11.3345 9.19889 11.1411 9.1738 10.9302L9.16797 10.8327V2.49935C9.16797 2.27834 9.25577 2.06637 9.41205 1.91009C9.56833 1.75381 9.78029 1.66602 10.0013 1.66602Z"
        fill="#808080"
      />
    </g>
    <defs>
      <clipPath id="clip0_7346_3817">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
