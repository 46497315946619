import React, { memo } from 'react'
import { Transaction } from './Transaction'
import { Stats } from './Stas'
import { Link } from 'react-router-dom'

export function AllPoolDetail() {
  return (
    <section className="w-full max-w-[1232px] px-[16px] mx-auto mt-[64px]">
      <div className="flex items-center gap-1 mb-3">
        <Link to={'/pool'} className="text-sm text-[#4C4C4C]">
          ← Back to Pool
        </Link>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-[1fr_389px] gap-[48px]">
        <Transaction />
        <Stats />
      </div>
    </section>
  )
}
