import { Percent, TradeType } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import { useCallback } from 'react'
import { InterfaceTrade, TradeFillType } from 'state/routing/types'
import { isClassicTrade } from 'state/routing/utils'

import { TransactionResponse } from '@ethersproject/providers'
import { useTransactionAdder } from '../state/transactions/hooks'
import {
  ExactInputSwapTransactionInfo,
  ExactOutputSwapTransactionInfo,
  TransactionType,
} from '../state/transactions/types'
import { currencyId } from '../utils/currencyId'
import { useSmartRouterSwapCallback } from './useSwapRouterCallback'

export type SwapResult = Awaited<ReturnType<ReturnType<typeof useSwapCallback>>>

// fix UniswapX type does not exist
type SwapCallbackResponse =
  | {
      type: TradeFillType.Classic
      response: TransactionResponse
      deadline?: undefined
    }
  | {
      type: TradeFillType.UniswapX
      response: { orderHash: string; deadline: number; encodedOrder: string }
      deadline?: undefined
    }

// type UniversalRouterFeeField = { feeOptions: FeeOptions } | { flatFeeOptions: FlatFeeOptions }
//
// function getUniversalRouterFeeFields(trade?: InterfaceTrade): UniversalRouterFeeField | undefined {
//   if (!isClassicTrade(trade)) return undefined
//   if (!trade.swapFee) return undefined
//
//   if (trade.tradeType === TradeType.EXACT_INPUT) {
//     return { feeOptions: { fee: trade.swapFee.percent, recipient: trade.swapFee.recipient } }
//   } else {
//     return { flatFeeOptions: { amount: BigNumber.from(trade.swapFee.amount), recipient: trade.swapFee.recipient } }
//   }
// }

// Returns a function that will execute a swap, if the parameters are all valid
// and the user has approved the slippage adjusted input amount for the trade
export function useSwapCallback(
  trade: InterfaceTrade | undefined, // trade to execute, required
  // fiatValues: { amountIn?: number; amountOut?: number; feeUsd?: number }, // usd values for amount in and out, and the fee value, logged for analytics
  allowedSlippage: Percent // in bips
) {
  const addTransaction = useTransactionAdder()
  // const addOrder = useAddOrder()
  const { account, chainId } = useWeb3React()

  // const uniswapXSwapCallback = useUniswapXSwapCallback({
  //   trade: isUniswapXTrade(trade) ? trade : undefined,
  //   allowedSlippage,
  //   fiatValues,
  // })

  // const universalRouterSwapCallback = useUniversalRouterSwapCallback(
  //   isClassicTrade(trade) ? trade : undefined,
  //   fiatValues,
  //   {
  //     slippageTolerance: allowedSlippage,
  //     permit: undefined,
  //     ...getUniversalRouterFeeFields(trade),
  //   }
  // )

  // const swapCallback = isUniswapXTrade(trade) ? uniswapXSwapCallback : universalRouterSwapCallback

  const swapCallback = useSmartRouterSwapCallback(isClassicTrade(trade) ? trade : undefined, {
    slippageTolerance: allowedSlippage,
  })

  return useCallback(async () => {
    if (!trade) throw new Error('missing trade')
    if (!account || !chainId) throw new Error('wallet must be connected to swap')

    // const result = await swapCallback()
    const result = (await swapCallback()) as SwapCallbackResponse

    const swapInfo: ExactInputSwapTransactionInfo | ExactOutputSwapTransactionInfo = {
      type: TransactionType.SWAP,
      inputCurrencyId: currencyId(trade.inputAmount.currency),
      outputCurrencyId: currencyId(trade.outputAmount.currency),
      isUniswapXOrder: false,
      ...(trade.tradeType === TradeType.EXACT_INPUT
        ? {
            tradeType: TradeType.EXACT_INPUT,
            inputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
            expectedOutputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
            minimumOutputCurrencyAmountRaw: trade.minimumAmountOut(allowedSlippage).quotient.toString(),
          }
        : {
            tradeType: TradeType.EXACT_OUTPUT,
            maximumInputCurrencyAmountRaw: trade.maximumAmountIn(allowedSlippage).quotient.toString(),
            outputCurrencyAmountRaw: trade.outputAmount.quotient.toString(),
            expectedInputCurrencyAmountRaw: trade.inputAmount.quotient.toString(),
          }),
    }

    if (result.type === TradeFillType.UniswapX) {
      // addOrder(
      //   account,
      //   result.response.orderHash,
      //   chainId,
      //   result.response.deadline,
      //   swapInfo as UniswapXOrderDetails['swapInfo'],
      //   result.response.encodedOrder,
      //   isUniswapXTrade(trade) ? trade.offchainOrderType : OffchainOrderType.DUTCH_AUCTION // satisfying type-checker; isUniswapXTrade should always be true
      // )
    } else {
      addTransaction(result.response, swapInfo)
    }

    return result
  }, [account, addTransaction, allowedSlippage, chainId, swapCallback, trade])
}
