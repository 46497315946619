import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Row from 'components/Row'
import { ArrowRight } from 'react-feather'
import { InterfaceTrade } from 'state/routing/types'
import { useTheme } from 'styled-components'
import { ThemedText } from 'theme/components'
import { useFormatter } from 'utils/formatNumbers'

export function TradeSummary({ trade }: { trade: Pick<InterfaceTrade, 'inputAmount' | 'outputAmount'> }) {
  const theme = useTheme()
  const { formatReviewSwapCurrencyAmount } = useFormatter()

  return (
    <>
      <div className="w-full pt-[12px] pb-[10px]">
        <Row style={{ gap: 32 }} justify="center" align="center">
          <div className="flex items-center gap-[4px]">
            <CurrencyLogo currency={trade.inputAmount.currency} size="16px" />
            <ThemedText.BodyPrimary color="neutral1">
              {formatReviewSwapCurrencyAmount(trade.inputAmount)} {trade.inputAmount.currency.symbol}
            </ThemedText.BodyPrimary>
          </div>

          <ArrowRight color={theme.neutral1} size="12px" />

          <div className="flex items-center gap-[4px]">
            <CurrencyLogo currency={trade.outputAmount.currency} size="16px" />
            <ThemedText.BodyPrimary color="neutral1">
              {formatReviewSwapCurrencyAmount(trade.outputAmount)} {trade.outputAmount.currency.symbol}
            </ThemedText.BodyPrimary>
          </div>
        </Row>
      </div>

      <div className="break-linear" />
    </>
  )
}
