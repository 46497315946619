import holdlogo from '../../assets/images/holdstation.png';
import { Link, NavLink } from 'react-router-dom';

export function HoldstationIcon() {
  return (
    <NavLink to="/swap" className="w-[73px] aspect-[73/36]">
      <img src={holdlogo} alt="web icon" />
    </NavLink>
  );
}
