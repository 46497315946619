import { Trans } from '@lingui/macro'
import clsx from 'clsx'
import { Link, NavLink, useLocation } from 'react-router-dom'

export function PoolHeader() {
  const { pathname } = useLocation()

  return (
    <div className="mb-[24px] flex items-center justify-between">
      <div className="flex items-center gap-[24px]">
        <Link
          to={'/pool'}
          className={clsx(
            'text-[18px] md:text-[24px] leading-[24x] md:leading-[32px] tracking-[-0.48px] text-[#4C4C4C]',
            {
              'text-white': pathname === '/pool',
            }
          )}
        >
          All Pools
        </Link>

        <Link
          to={'/pool/my-pool'}
          className={clsx(
            'text-[18px] md:text-[24px] leading-[24x] md:leading-[32px] tracking-[-0.48px] text-[#4C4C4C]',
            {
              'text-white': pathname === '/pool/my-pool' || pathname === '/detail/',
            }
          )}
        >
          My Pools
        </Link>
      </div>

      <Link
        to={'/add/ETH'}
        className="flex items-center gap-[8px] px-[20px] py-[14px] rounded-full bg-white text-[14px] leading-[16px] font-medium tracking-[-0.28px] text-black"
      >
        <span>+</span>
        <span>
          <Trans>Add Liquidity</Trans>
        </span>
      </Link>
    </div>
  )
}
