export function Close() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.0001 13.1523L16.6091 17.7614C16.9273 18.0795 17.4432 18.0795 17.7614 17.7614C18.0795 17.4431 18.0795 16.9273 17.7614 16.609L13.1523 12L17.7614 7.39092C18.0795 7.07274 18.0795 6.55684 17.7614 6.23866C17.4432 5.92046 16.9273 5.92046 16.6091 6.23866L12.0001 10.8477L7.39091 6.23864C7.07272 5.92045 6.55683 5.92045 6.23864 6.23864C5.92045 6.55684 5.92045 7.07272 6.23864 7.39091L10.8477 12L6.23864 16.6091C5.92045 16.9273 5.92045 17.4431 6.23864 17.7614C6.55683 18.0795 7.07272 18.0795 7.39091 17.7614L12.0001 13.1523Z"
        fill="#808080"
      />
    </svg>
  );
}
