import { useAppSelector } from 'state/hooks';
import { ChartInput } from './ChartInput';
import { ChartOutput } from './ChartOutput';
import { useSwapContext } from 'state/swap/hooks';
import { Field } from 'components/swap/constants';

export function Chart() {
  const userShowChart = useAppSelector((state) => state.user.userShowChart);

  const { derivedSwapInfo } = useSwapContext();
  const { currencies } = derivedSwapInfo;
  const outputCurrency = currencies[Field.OUTPUT] ?? undefined;

  if (!userShowChart) {
    return null;
  }

  return (
    <div className="flex-1 flex flex-col gap-[83px]">
      <ChartInput />

      {outputCurrency && <ChartOutput outputCurrency={outputCurrency} />}
    </div>
  );
}
