import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { InterfaceTrade } from 'state/routing/types'
import { useFormatter } from 'utils/formatNumbers'

export function TradeSummaryV2({ trade }: { trade: Pick<InterfaceTrade, 'inputAmount' | 'outputAmount'> }) {
  const { formatReviewSwapCurrencyAmount } = useFormatter()

  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex items-center gap-[4px]">
        <CurrencyLogo currency={trade.inputAmount.currency} size="20px" />

        <h4 className="text-[16px] leading-[24px] tracking-[-0.32px] text-white">
          {formatReviewSwapCurrencyAmount(trade.inputAmount)} {trade.inputAmount.currency.symbol}
        </h4>
      </div>

      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="28" viewBox="0 0 20 28" fill="none">
        <path
          d="M9.64645 27.3536C9.84171 27.5488 10.1583 27.5488 10.3536 27.3536L13.5355 24.1716C13.7308 23.9763 13.7308 23.6597 13.5355 23.4645C13.3403 23.2692 13.0237 23.2692 12.8284 23.4645L10 26.2929L7.17157 23.4645C6.97631 23.2692 6.65973 23.2692 6.46447 23.4645C6.2692 23.6597 6.2692 23.9763 6.46447 24.1716L9.64645 27.3536ZM9.5 3L9.5 4L10.5 4L10.5 3L9.5 3ZM9.5 6L9.5 8L10.5 8L10.5 6L9.5 6ZM9.5 10L9.5 12L10.5 12L10.5 10L9.5 10ZM9.5 14L9.5 16L10.5 16L10.5 14L9.5 14ZM9.5 18L9.5 20L10.5 20L10.5 18L9.5 18ZM9.5 22L9.5 24L10.5 24L10.5 22L9.5 22ZM9.5 26L9.5 27L10.5 27L10.5 26L9.5 26Z"
          fill="url(#paint0_linear_6619_3078)"
        />
        <defs>
          <linearGradient id="paint0_linear_6619_3078" x1="9.5" y1="27" x2="9.5" y2="3" gradientUnits="userSpaceOnUse">
            <stop stopColor="#808080" />
            <stop offset="1" stopColor="#808080" stopOpacity="0.1" />
          </linearGradient>
        </defs>
      </svg>

      <div className="flex items-center gap-[4px]">
        <CurrencyLogo currency={trade.outputAmount.currency} size="20px" />

        <h4 className="text-[16px] leading-[24px] tracking-[-0.32px] text-white">
          {formatReviewSwapCurrencyAmount(trade.outputAmount)} {trade.outputAmount.currency.symbol}
        </h4>
      </div>
    </div>
  )
}
