import { Currency } from '@uniswap/sdk-core'
import BigNumber from 'bignumber.js'
import { useQuery } from 'react-query'

interface PointResponse {
  estimate: string
}

export interface TokenInProps {
  address: string
  decimals: number
}

async function fetchEstimate({ queryKey }: { queryKey: any }) {
  const amount_in = queryKey[1]
  const token_in: TokenInProps = queryKey[2]
  const token_out: TokenInProps = queryKey[3]
  const output = queryKey[4]

  if (!token_in.address || !token_out.address || !amount_in || !output) {
    return undefined
  }

  const amount = new BigNumber(amount_in).multipliedBy(Math.pow(10, token_in.decimals)).toFixed()

  const url = `https://stats-api.hold.so/v1/points/estimate?action=swap&token_in=${token_in.address}&token_out=${token_out.address}&amount_in=${amount}`

  const response = await fetch(url)
  if (!response.ok) {
    throw new Error('Network response was not ok')
  }

  return response.json()
}

export function useEstimate(token_in: TokenInProps, token_out: TokenInProps, typedValue: string, output?: Currency) {
  const data = useQuery<PointResponse>({
    queryKey: ['estimate', typedValue, token_in, token_out, output],
    queryFn: fetchEstimate,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    cacheTime: 60000,
    refetchInterval: 15000,
  })

  return data
}
