import clsx from 'clsx'
import { PoolResponse, useQueryAllPool } from './useQueryAllPool'
import { useCallback, useMemo, useState } from 'react'
import BigNumber from 'bignumber.js'
import { formatDisplay } from 'pages/Swap/Chart/format-balance'
import { useWeb3React } from '@web3-react/core'
import { useToken } from 'hooks/Tokens'
import { unwrappedToken } from 'utils/unwrappedToken'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import sorticon from 'assets/svg/sort.svg'
import { LoadingTable } from './loading-table'
import { useNavigate } from 'react-router-dom'
import { WETH } from 'pages/AddLiquidity/EstimatedPoint'

export function formatBalance(num: string) {
  const data = new BigNumber(num).toNumber()

  if (new BigNumber(num).gte(1_000_000_000)) {
    return formatDisplay(data / 1_000_000_000, { decimalToShow: 1 }) + 'B'
  }

  if (new BigNumber(num).gte(1_000_000)) {
    return formatDisplay(data / 1_000_000, { decimalToShow: 1 }) + 'M'
  }

  if (new BigNumber(num).gte(1_000)) {
    return formatDisplay(data / 1_000, { decimalToShow: 1 }) + 'K'
  }

  return formatDisplay(data, { decimalToShow: 2, minimumDecimalToShow: 2 })
}

export function LogoImage({ id0, id1 }: { id0: string; id1: string }) {
  const { chainId } = useWeb3React()

  const token0 = useToken(id0, chainId)
  const token1 = useToken(id1, chainId)

  const currency0 = useMemo(() => (token0 ? unwrappedToken(token0) : undefined), [token0])
  const currency1 = useMemo(() => (token1 ? unwrappedToken(token1) : undefined), [token1])

  return <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={24} />
}

export function AllPool() {
  const [sort, setSort] = useState<'desc' | 'asc'>('desc')
  const [sortType, setSortType] = useState(0)
  const { data, loading } = useQueryAllPool('desc')
  const navigation = useNavigate()

  function toggleSort(type: 0 | 1 | 2) {
    if (sort === 'asc') {
      setSort('desc')
    } else {
      setSort('asc')
    }

    setSortType(type)
  }

  const allpools = useMemo(() => {
    if (!data?.pools) {
      return []
    }

    const now = Math.floor(Date.now() / 1000)

    const result = data.pools.map((item) => {
      const initial = new BigNumber(0)

      const volume7D = item.poolHourData.reduce((prev, current) => {
        return new BigNumber(current.volumeUSD).plus(prev)
      }, initial)

      const filterVolumn1D = item.poolHourData.filter((v) => v.periodStartUnix >= now - 24 * 60 * 60)
      const volume1D = filterVolumn1D.reduce((prev, current) => {
        return new BigNumber(current.volumeUSD).plus(prev)
      }, new BigNumber(0))

      return {
        ...item,
        volume7D,
        volume1D,
      }
    })

    const sData = result.sort((a, b) => {
      if (sortType === 0) {
        const compare1 = new BigNumber(b.totalValueLockedUSD).minus(new BigNumber(a.totalValueLockedUSD)).toNumber()
        const compare2 = new BigNumber(a.totalValueLockedUSD).minus(new BigNumber(b.totalValueLockedUSD)).toNumber()

        if (sort === 'asc') {
          return compare2
        }

        return compare1
      }

      if (sortType === 1) {
        const compare1 = new BigNumber(b.volumeUSD).minus(new BigNumber(a.volumeUSD)).toNumber()
        const compare2 = new BigNumber(a.volumeUSD).minus(new BigNumber(b.volumeUSD)).toNumber()

        if (sort === 'asc') {
          return compare2
        }

        return compare1
      }

      if (sortType === 2) {
        const compare1 = b.volume7D.minus(a.volume7D).toNumber()
        const compare2 = a.volume7D.minus(b.volume7D).toNumber()

        if (sort === 'asc') {
          return compare2
        }

        return compare1
      }

      return -1
    })

    return sData
  }, [data?.pools, sort, sortType])

  const navigate = useCallback(
    (item: PoolResponse) => {
      const address1 = item.token0.id === WETH ? 'ETH' : item.token0.id
      const address2 = item.token1.id === WETH ? 'ETH' : item.token1.id

      return navigation(`/add/${address1}/${address2}/default/${item.id}`)
    },
    [navigation]
  )

  if (loading) {
    return <LoadingTable />
  }

  return (
    <div className="rounded-2xl border border-[#171717] overflow-auto">
      <table className="w-full border-collapse">
        <thead>
          <tr className="[&>th]:border-[#171717] [&>th]:border-b-[1px] [&>th]:text-base [&>th]:text-[#4C4C4C] [&>th]:whitespace-nowrap">
            <th className={clsx('text-left px-[20px] py-[20px] font-normal')}>#</th>
            <th className="text-left py-[20px] font-normal">Pool</th>
            <th className="py-[20px] font-normal">
              <div
                className="px-2 grid grid-cols-[24px_min-content] justify-center cursor-pointer"
                onClick={() => toggleSort(0)}
              >
                <img className="flex-shrink-0" src={sorticon} alt="sort icon" width={24} height={24} loading="lazy" />
                <span>TVL</span>
              </div>
            </th>
            <th className="text-right py-[20px] font-normal">
              <div
                className="px-2 grid grid-cols-[24px_min-content] justify-end cursor-pointer"
                onClick={() => toggleSort(1)}
              >
                <img className="flex-shrink-0" src={sorticon} alt="sort icon" width={24} height={24} loading="lazy" />
                <span>Volume (24h)</span>
              </div>
            </th>
            <th className="text-right py-[20px] font-normal pr-[20px]">
              <div
                className="grid grid-cols-[24px_min-content] justify-end cursor-pointer"
                onClick={() => toggleSort(2)}
              >
                <img className="flex-shrink-0" src={sorticon} alt="sort icon" width={24} height={24} loading="lazy" />
                <span> 7 Day volume</span>
              </div>
            </th>
          </tr>
        </thead>

        <tbody>
          {allpools.map((item, idx) => {
            const symbol1 = item.token0.id === WETH ? 'ETH' : item.token0.symbol
            const symbol2 = item.token1.id === WETH ? 'ETH' : item.token1.symbol

            return (
              <tr key={idx} className="cursor-pointer" onClick={() => navigate(item)}>
                <td className="text-base py-[20px] px-[20px]">{idx + 1}</td>
                <td className="text-base py-[20px] flex items-center gap-1">
                  <div>
                    <LogoImage id0={item.token0.id} id1={item.token1.id} />
                  </div>
                  <span>{`${symbol1}/${symbol2}`}</span>
                </td>
                <td className="text-center text-base py-[20px]">${formatBalance(item.totalValueLockedUSD)}</td>
                <td className="text-right text-base py-[20px]">${formatBalance(item.volume1D.toString())}</td>
                <td className="text-right text-base py-[20px] pr-[20px]">${formatBalance(item.volume7D.toString())}</td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
