import { useUserSlippageTolerance } from 'state/user/hooks'
import chart from '../../../assets/images/chart.svg'
import { ModalSlippage } from './ModalSlippage'
import { useFormatter } from 'utils/formatNumbers'
import { Percent } from '@uniswap/sdk-core'
import { lg } from 'make-plural'
import { SlippageTolerance } from 'state/user/types'
import { isUniswapXTrade } from 'state/routing/utils'
import { InterfaceTrade } from 'state/routing/types'
import { useCallback, useMemo, useState } from 'react'

export function SlippageHeader({ compact, trade }: { compact: boolean; trade?: InterfaceTrade }) {
  const [isVisible, setIsVisible] = useState(false)

  const [userSlippageTolerance] = useUserSlippageTolerance()
  const { formatPercent } = useFormatter()

  const toggle = useCallback(() => {
    setIsVisible((v) => !v)
  }, [setIsVisible])

  const percentage = useMemo(() => {
    if (userSlippageTolerance === SlippageTolerance.Auto || isUniswapXTrade(trade)) {
      return SlippageTolerance.Auto
    }

    return `Slippage ${formatPercent(userSlippageTolerance as Percent)}`
  }, [userSlippageTolerance, trade])

  return (
    <>
      <button onClick={toggle} className="flex items-center gap-[4px] px-[20px] py-[10px] rounded-full bg-[#121212]">
        <p className="text-[14px] leading-[20px] tracking-[-0.28px] text-[#808080] capitalize">{percentage}</p>

        <img src={chart} alt="chart" width={20} height={20} loading="lazy" />
      </button>

      {isVisible && <ModalSlippage isVisible={isVisible} setIsVisible={setIsVisible} />}
    </>
  )
}
