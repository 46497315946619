import Skeleton from 'react-loading-skeleton'

export function LoadingToken() {
  return (
    <div className="flex flex-col gap-8">
      {Array.from({ length: 5 }).map((item, idx) => {
        return (
          <div key={idx} className="flex items-center justify-between gap-[12px] mx-8">
            <div className="w-[36px] aspect-square rounded-full">
              <Skeleton width={36} height={36} baseColor="#202020" highlightColor="#444" className="rounded-full" />
            </div>

            <div className="flex flex-col gap-[6px]">
              <Skeleton width={36} height={16} baseColor="#202020" highlightColor="#444" className="rounded-full" />
              <Skeleton width={40} height={12} baseColor="#202020" highlightColor="#444" className="rounded-full" />
            </div>

            <div className="flex flex-col gap-[6px]">
              <Skeleton width={72} height={16} baseColor="#202020" highlightColor="#444" className="rounded-full" />
              <Skeleton width={72} height={12} baseColor="#202020" highlightColor="#444" className="rounded-full" />
            </div>
          </div>
        )
      })}
    </div>
  )
}
