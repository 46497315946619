export function Transaction() {
  return (
    <div className="order-1 md:order-0">
      <p className="text-[24px] leading-[32px] tracking-[-0.48px] mb-[16px]">Transaction</p>

      <div className="border border-[#171717] rounded-[16px]">
        <table className="w-full border-collapse">
          <thead>
            <tr className="[&>th]:text-base [&>th]:text-[#4C4C4C] [&>th]:py-[16px] [&>th]:border-b [&>th]:border-b-[#171717] [&>th]:font-normal">
              <th className="pl-[20px] text-left">Time</th>
              <th className="text-left">Type</th>
              <th className="text-right">USD</th>
              <th className="text-right">USDC</th>
              <th className="text-right">USDT</th>
              <th className="pr-[20px] text-right">Wallet</th>
            </tr>
          </thead>

          <tbody>
            <tr className="[&>td]:text-base [&>td]:py-[20px]">
              <td className="pl-[20px]">--</td>
              <td>--</td>
              <td className="text-right">--</td>
              <td className="text-right">--</td>
              <td className="text-right">--</td>
              <td className="pr-[20px] text-right">--</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  )
}
