import { Currency } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { useToken } from 'hooks/Tokens'
import useNativeCurrency from 'lib/hooks/useNativeCurrency'
import { useMemo } from 'react'
import { unwrappedToken } from 'utils/unwrappedToken'

const tokens = {
  hold: '0xed4040fd47629e7c8fbb7da76bb50b3e7695f0f2',
  usdc: '0x1d17CBcF0D6D143135aE902365D2E5e2A16538D4',
}
const zkChainId = 324

export function SelectToken({ onCurrencySelect }: { onCurrencySelect?: (currency: Currency) => void }) {
  const { chainId } = useWeb3React()

  const hold = useToken(tokens.hold, chainId)
  const usdc = useToken(tokens.usdc, chainId)
  const native = useNativeCurrency(chainId)

  const currency_hold = useMemo(() => (hold && chainId === zkChainId ? unwrappedToken(hold) : undefined), [hold])
  const currency_usdc = useMemo(() => (usdc && chainId === zkChainId ? unwrappedToken(usdc) : undefined), [usdc])
  const currency_eth = useMemo(() => (native && chainId === zkChainId ? unwrappedToken(native) : undefined), [native])

  return (
    <div className="flex items-center gap-2">
      <button
        onClick={() => {
          if (currency_eth && onCurrencySelect) {
            onCurrencySelect(currency_eth)
          }
        }}
        className="w-[32px] aspect-square p-[4px] bg-[#292929] rounded-full"
      >
        <CurrencyLogo currency={currency_eth} size="24px" />
      </button>

      <button
        onClick={() => {
          if (currency_hold && onCurrencySelect) {
            onCurrencySelect(currency_hold)
          }
        }}
        className="w-[32px] aspect-square p-[4px] bg-[#292929] rounded-full"
      >
        <CurrencyLogo currency={currency_hold} size="24px" />
      </button>

      <button
        onClick={() => {
          if (currency_usdc && onCurrencySelect) {
            onCurrencySelect(currency_usdc)
          }
        }}
        className="w-[32px] aspect-square p-[4px] bg-[#292929] rounded-full"
      >
        <CurrencyLogo currency={currency_usdc} size="24px" />
      </button>
    </div>
  )
}
