import clsx from 'clsx'
import sorticon from 'assets/svg/sort.svg'
import Skeleton from 'react-loading-skeleton'

export function LoadingTable() {
  return (
    <div className="rounded-2xl border border-[#171717]">
      <table className="w-full border-collapse">
        <thead>
          <tr className="[&>th]:border-[#171717] [&>th]:border-b-[1px] [&>th]:text-base [&>th]:text-[#4C4C4C]">
            <th className={clsx('text-left px-[20px] py-[20px] font-normal')}>#</th>
            <th className="text-left py-[20px] font-normal">Pool</th>
            <th className="py-[20px] font-normal">
              <div className="flex items-center justify-center cursor-pointer">
                <img src={sorticon} alt="sort icon" width={24} height={24} loading="lazy" />
                <span>TVL</span>
              </div>
            </th>
            <th className="text-right py-[20px] font-normal">Volume (24h)</th>
            <th className="text-right py-[20px] font-normal">7 Day volume</th>
            <th className="text-right py-[20px] font-normal px-5">APR</th>
          </tr>
        </thead>

        <tbody>
          {[1, 2, 3].map((item, idx) => {
            return (
              <tr key={idx}>
                <td className="py-[20px] px-[20px]">
                  <Skeleton height={24} width={24} baseColor="#202020" highlightColor="#444" />
                </td>

                <td className="py-[20px] flex items-center gap-1">
                  <Skeleton height={24} width={40} baseColor="#202020" highlightColor="#444" />

                  <Skeleton height={24} width={80} baseColor="#202020" highlightColor="#444" />
                </td>

                <td className="py-[20px]">
                  <div className="flex items-center justify-center">
                    <Skeleton height={24} width={80} baseColor="#202020" highlightColor="#444" className="mx-auto" />
                  </div>
                </td>

                <td className="py-[20px]">
                  <div className="flex items-center justify-end">
                    <Skeleton height={24} width={80} baseColor="#202020" highlightColor="#444" className="mx-auto" />
                  </div>
                </td>

                <td className="py-[20px]">
                  <div className="flex items-center justify-end">
                    <Skeleton height={24} width={80} baseColor="#202020" highlightColor="#444" className="mx-auto" />
                  </div>
                </td>

                <td className="py-[20px] px-[20px]">
                  <div className="flex items-center justify-end">
                    <Skeleton height={24} width={80} baseColor="#202020" highlightColor="#444" className="mx-auto" />
                  </div>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
