import { defaultAbiCoder } from '@ethersproject/abi'
import { getAddress } from '@ethersproject/address'
import { hexDataSlice } from '@ethersproject/bytes'
import { keccak256, pack } from '@ethersproject/solidity'
import { Token } from '@uniswap/sdk-core'
import { FeeAmount, POOL_INIT_CODE_HASH } from '../constants'

/**
 * solidity - bytes32(uint256(uint160(address)))
 * @param address
 */
function addressAsUint256(address: string): string {
  let result = address.substring(2)
  while (result.length < 64) {
    result = '0' + result
  }
  return `0x${result}`
}

/**
 * Computes a pool address
 * https://explorer.zksync.io/address/0x7FE3975fb0f9A7F78b015806fB8a1E569b014C10#contract#PoolAddress.sol
 * @param factoryAddress The Uniswap V3 factory address
 * @param tokenA The first token of the pair, irrespective of sort order
 * @param tokenB The second token of the pair, irrespective of sort order
 * @param fee The fee tier of the pool
 * @param initCodeHashManualOverride Override the init code hash used to compute the pool address if necessary
 * @returns The pool address
 */
export function computePoolAddress({
  factoryAddress,
  tokenA,
  tokenB,
  fee,
  initCodeHashManualOverride,
}: {
  factoryAddress: string
  tokenA: Token
  tokenB: Token
  fee: FeeAmount
  initCodeHashManualOverride?: string
}): string {
  const [token0, token1] = tokenA.sortsBefore(tokenB) ? [tokenA, tokenB] : [tokenB, tokenA] // does safety checks
  // return getCreate2Address(
  //   factoryAddress,
  //   keccak256(
  //     ['bytes'],
  //     [
  //       defaultAbiCoder.encode(
  //         ['address', 'address', 'uint24'],
  //         [token0.address, token1.address, fee]
  //       ),
  //     ]
  //   ),
  //   initCodeHashManualOverride ?? POOL_INIT_CODE_HASH
  // )
  const salt = keccak256(
    ['bytes'],
    [
      defaultAbiCoder.encode(
        ['address', 'address', 'uint24'],
        [token0.address, token1.address, fee]
      ),
    ]
  )
  const hexData = keccak256(
    ['bytes'],
    [
      pack(
        ['bytes', 'bytes', 'bytes', 'bytes', 'bytes'],
        [
          '0x2020dba91b30cc0006188af794c2fb30dd8520db7e2c088b7fc7c103c00ca494', // keccak256("zksyncCreate2")
          addressAsUint256(factoryAddress),
          salt,
          initCodeHashManualOverride ?? POOL_INIT_CODE_HASH,
          '0xc5d2460186f7233c927e7db2dcc703c0e500b653ca82273b7bfad8045d85a470', // constructor input hash: keccak256("")
        ]
      ),
    ]
  )
  return getAddress(hexDataSlice(hexData, 12))
}
