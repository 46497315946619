import swap from 'assets/svg/swap.svg'
import plus from 'assets/svg/plus.svg'
import { useGetDetailPool } from './data'
import { useWeb3React } from '@web3-react/core'
import { useToken } from 'hooks/Tokens'
import { useMemo } from 'react'
import { unwrappedToken } from 'utils/unwrappedToken'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import { formatBalance } from 'pages/Pool/all-pool'
import { Link, useParams } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import { formatDisplay } from 'pages/Swap/Chart/format-balance'

export function Stats() {
  const { id } = useParams<{ id?: string }>()

  const { data } = useGetDetailPool(id)
  const { chainId } = useWeb3React()

  const id1 = data?.pool.token0.id || ''
  const id2 = data?.pool.token1.id || ''

  const token0 = useToken(id1, chainId)
  const token1 = useToken(id2, chainId)

  const currency0 = useMemo(() => (token0 ? unwrappedToken(token0) : undefined), [token0])
  const currency1 = useMemo(() => (token1 ? unwrappedToken(token1) : undefined), [token1])

  const balanceData = useMemo(() => {
    if (!data?.pool) {
      return {
        b0: '--',
        b1: '--',
      }
    }

    const b0 = new BigNumber(data.pool.reserve0).toNumber()
    const b1 = new BigNumber(data.pool.reserve1).toNumber()

    return {
      b0: formatDisplay(b0, { decimalToShow: 5 }),
      b1: formatDisplay(b1, { decimalToShow: 5 }),
    }
  }, [data])

  return (
    <div className="order-0 md:order-1">
      <p className="text-[24px] leading-8 mb-4 tracking-[-0.48px]">Stats</p>

      <div className="p-8 rounded-2xl border border-[#141414] flex flex-col justify-center items-start gap-[22px]">
        <div className="flex self-stretch items-center gap-2">
          <Link
            to={'/swap'}
            className="flex-1 h-11 px-5 bg-white rounded-[90px] flex justify-center items-center gap-2"
          >
            <img src={swap} alt="swap" width={17} height={16} loading="lazy" />

            <span className="text-center text-black text-sm font-medium capitalize">Swap</span>
          </Link>

          <Link
            to="/add/ETH"
            className="flex-1 h-11 px-5 bg-white rounded-[90px] flex justify-center items-center gap-2"
          >
            <img src={plus} alt="plus" width={17} height={16} loading="lazy" />

            <span className="text-center text-black text-sm font-medium capitalize">Add LP</span>
          </Link>
        </div>

        <div className="self-stretch flex flex-col gap-2">
          <p className="text-[#4C4C4C] text-sm leading-tight">Pool balances</p>

          <div className="self-stretch flex-col justify-start items-start gap-2 flex">
            <div className="self-stretch flex justify-between">
              <div className="flex items-center gap-1 ">
                <p className="text-white text-base">{balanceData.b0}</p>

                <div className="flex items-center gap-1">
                  <CurrencyLogo currency={currency0} size={`16px`} />

                  <div className="text-white text-base font-normal font-['Inter'] leading-normal">
                    {currency0?.symbol}
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-1">
                <p className="text-white text-base">{balanceData.b1}</p>

                <div className="justify-start items-center gap-1 flex">
                  <CurrencyLogo currency={currency1} size={`16px`} />

                  <div className="text-white text-base font-normal font-['Inter'] leading-normal">
                    {currency1?.symbol}
                  </div>
                </div>
              </div>
            </div>

            <div className="h-1 pl-[174px] bg-emerald-400 justify-end items-center inline-flex">
              <div className="w-[151px] h-1 relative bg-blue-600" />
            </div>
          </div>
        </div>

        <div className="self-stretch flex flex-col justify-start items-start gap-8">
          <div className="flex flex-col items-start gap-2">
            <p className="text-neutral-600 text-sm">TVL</p>
            <p className="text-white text-2xl">${formatBalance(data?.pool.totalValueLockedUSD || '0')}</p>
          </div>
          <div className="flex flex-col items-start gap-2">
            <p className="text-neutral-600 text-sm">24H volume</p>
            <div className="text-white text-2xl">${formatBalance(data?.pool.volumeUSD || '0')}</div>
          </div>
          <div className="flex flex-col items-start gap-2">
            <p className="text-neutral-600 text-sm">24H fees</p>
            <p className="text-white text-2xl">${formatBalance(data?.pool.feesUSD || '0')}</p>
          </div>
          <div className="flex flex-col items-start gap-2">
            <p className="text-neutral-600 text-sm">Point Reward</p>
            <p className="text-white text-2xl">--</p>
          </div>
        </div>
      </div>
    </div>
  )
}
