import { useMemo } from 'react'
import { useGetTokens } from './data'
import BigNumber from 'bignumber.js'
import { formatDisplay } from 'pages/Swap/Chart/format-balance'
import { LoadingToken } from './Loading'
import questionMark from 'assets/svg/question-mark.svg'

export function NewTokens() {
  const { data, isLoading } = useGetTokens()

  const formatData = useMemo(() => {
    if (!data) {
      return undefined
    }

    const filter = data.filter((item) => item.type !== 'unverified')

    return filter.map((item) => {
      const amount = new BigNumber(item.amount).dividedBy(Math.pow(10, item.contract_decimals ?? 18))
      const amountBalance = amount.multipliedBy(new BigNumber(item.quote_rate))

      return {
        ...item,
        formatAmount: formatDisplay(amount.toNumber(), { decimalToShow: 5 }),
        amountBalance: isNaN(amountBalance.toNumber())
          ? '0'
          : formatDisplay(amountBalance.toNumber(), { decimalToShow: 5 }),
      }
    })
  }, [data])

  if (isLoading) {
    return <LoadingToken />
  }

  if (!formatData) {
    return <></>
  }

  return (
    <div className="flex flex-col gap-[32px]">
      {formatData.map((item, idx) => {
        const logo_url = item.logo_url ?? questionMark

        return (
          <div key={idx} className="flex items-center justify-between gap-3 px-[24px]">
            <div className="w-9 aspect-square rounded-full overflow-hidden">
              <img src={logo_url} alt="icon token" width={36} height={36} loading="lazy" />
            </div>

            <div className="flex-1 flex flex-col gap-[6px]">
              <p className="text-[16px] leading-4 font-medium tracking-[-0.32px]">{item.contract_ticker_symbol}</p>
              <p className="text-[12px] leading-3 text-[#808080] tracking-[-0.28px]">{item.contract_name}</p>
            </div>

            <div className="flex flex-col items-end gap-[6px]">
              <p className="text-[16px] leading-4 font-medium tracking-[-0.32px]">{item.formatAmount}</p>
              <p className="text-[12px] leading-3 text-[#808080] tracking-[-0.28px]">${item.amountBalance}</p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
