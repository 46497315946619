import { ChainId } from '@uniswap/sdk-core'
import { SupportedInterfaceChain } from 'constants/chains'

// const INFURA_KEY = process.env.REACT_APP_INFURA_KEY
// if (typeof INFURA_KEY === 'undefined') {
//   throw new Error(`REACT_APP_INFURA_KEY must be a defined environment variable`)
// }
// const QUICKNODE_ARBITRUM_RPC_URL = process.env.REACT_APP_QUICKNODE_ARBITRUM_RPC_URL
// if (typeof QUICKNODE_ARBITRUM_RPC_URL === 'undefined') {
//   throw new Error(`REACT_APP_QUICKNODE_ARBITRUM_RPC_URL must be a defined environment variable`)
// }

/**
 * Public JSON-RPC endpoints.
 * These are used if the integrator does not provide an endpoint, or if the endpoint does not work.
 *
 * MetaMask allows switching to any URL, but displays a warning if it is not on the "Safe" list:
 * https://github.com/MetaMask/metamask-mobile/blob/bdb7f37c90e4fc923881a07fca38d4e77c73a579/app/core/RPCMethods/wallet_addEthereumChain.js#L228-L235
 * https://chainid.network/chains.json
 *
 * These "Safe" URLs are listed first, followed by other fallback URLs, which are taken from chainlist.org.
 */
export const PUBLIC_RPC_URLS: Record<SupportedInterfaceChain, string[]> = {
  // [ChainId.MAINNET]: [
  //   // "Safe" URLs
  //   'https://api.mycryptoapi.com/eth',
  //   'https://cloudflare-eth.com',
  //   // "Fallback" URLs
  //   'https://rpc.ankr.com/eth',
  //   'https://eth-mainnet.public.blastapi.io',
  // ],
  [ChainId.ZKSYNC]: [
    // "Safe" URLs
    'https://mainnet.era.zksync.io',
    // "Fallback" URLs
    'https://zksync.drpc.org',
    'https://zksync.meowrpc.com',
    'https://zksync-era.blockpi.network/v1/rpc/public',
    'https://rpc.ankr.com/zksync_era',
  ],
}

/**
 * to get ENS name
 */
export type SupportedInterfaceChainAndMainnet = SupportedInterfaceChain | ChainId.MAINNET

/**
 * Application-specific JSON-RPC endpoints.
 * These are URLs which may only be used by the interface, due to origin policies, &c.
 */
export const APP_RPC_URLS: Record<SupportedInterfaceChainAndMainnet, string[]> = {
  // [ChainId.MAINNET]: [`https://mainnet.infura.io/v3/${INFURA_KEY}`, QUICKNODE_MAINNET_RPC_URL],
  [ChainId.MAINNET]: ['https://api.mycryptoapi.com/eth', 'https://cloudflare-eth.com', 'https://rpc.ankr.com/eth'],
  [ChainId.ZKSYNC]: ['https://mainnet.era.zksync.io'],
}

export const INFURA_PREFIX_TO_CHAIN_ID: { [prefix: string]: ChainId } = {
  'zksync-mainnet': ChainId.ZKSYNC,
}
