import clsx from 'clsx'
import { ButtonPrimaryV2 } from 'components/Button/ButtonPrimaryV2'
import { Close } from 'components/Icon/Icon'
import { ModalCenter } from 'components/Modal/ModalV2'
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react'
import { useUserSlippageTolerance } from 'state/user/hooks'
import { SlippageTolerance } from 'state/user/types'
import { Percent } from '@uniswap/sdk-core'
import { useFormatter } from 'utils/formatNumbers'
import { useSwapContext } from 'state/swap/hooks'
import { NUMBERS_ONLY } from './SettingHeader'
const data = [
  { title: 'auto', value: '' },
  { title: '0.3%', value: '0.3' },
  { title: '0.5%', value: '0.5' },
  { title: '1%', value: '1' },
]

const MINIMUM_RECOMMENDED_SLIPPAGE = new Percent(5, 10_000)
const MAXIMUM_RECOMMENDED_SLIPPAGE = new Percent(1, 100)

function useFormatPercentInput() {
  const { formatPercent } = useFormatter()

  return (slippage: Percent) => formatPercent(slippage).slice(0, -1) // remove % sign
}

const NUMBER_WITH_MAX_TWO_DECIMAL_PLACES = /^(?:\d*\.\d{0,2}|\d+)$/

export function ModalSlippage({
  isVisible,
  setIsVisible,
}: {
  isVisible: boolean
  setIsVisible: Dispatch<SetStateAction<boolean>>
}) {
  const [userSlippageTolerance, setUserSlippageTolerance] = useUserSlippageTolerance()
  const formatPercentInput = useFormatPercentInput()
  const { formatPercent } = useFormatter()

  const {
    derivedSwapInfo: { autoSlippage },
  } = useSwapContext()

  const defaultSlippageInputValue =
    userSlippageTolerance !== SlippageTolerance.Auto ? formatPercentInput(userSlippageTolerance) : ''

  const [activeTab, setActiveTab] = useState(() => {
    const condition = data.findIndex((item) => item.value === defaultSlippageInputValue)

    return condition
  })
  const [slippageInput, setSlippageInput] = useState(() => {
    if (defaultSlippageInputValue === '') {
      return '0.5'
    }

    return defaultSlippageInputValue
  })

  const warning = useMemo(() => {
    try {
      const parsed = Math.floor(Number.parseFloat(slippageInput) * 100)

      const tooLow = new Percent(parsed, 10_000).lessThan(MINIMUM_RECOMMENDED_SLIPPAGE)
      const tooHigh = new Percent(parsed, 10_000).greaterThan(MAXIMUM_RECOMMENDED_SLIPPAGE)

      if (tooLow) {
        return {
          show: true,
          title: `In case of transactions with ultra-low slippage tolerance, there is a possibility of reversal due to minute market fluctuations`,
        }
      }

      if (tooHigh) {
        return {
          show: true,
          title: `You may receive ${formatPercent(
            new Percent(parsed, 10_000)
          )} less with this percentage of slippage tolerance`,
        }
      }

      return {
        show: false,
      }
    } catch (error) {
      return {
        show: false,
      }
    }
  }, [slippageInput])

  const toggleSlippage = useCallback(
    (item: { title: string; value: string }, idx: number) => {
      return () => {
        if (idx === 0) {
          setActiveTab(idx)
          return setSlippageInput('0.5')
        }

        setActiveTab(idx)
        setSlippageInput(item.value)
      }
    },
    [setActiveTab, activeTab]
  )

  const parseSlippageInput = (value: string) => {
    if (activeTab === 0) {
      setActiveTab(-1)
    }

    // Do not allow non-numerical characters in the input field or more than two decimals
    if (value.length > 0 && !NUMBER_WITH_MAX_TWO_DECIMAL_PLACES.test(value)) {
      return
    }

    setSlippageInput(value)
    // setSlippageError(false)
  }

  const saveSlippage = useCallback(() => {
    if (activeTab === 0) {
      setUserSlippageTolerance(SlippageTolerance.Auto)

      return setIsVisible((v) => !v)
    }

    if (slippageInput === '.') {
      return
    }

    // Parse user input and set the slippage if valid, error otherwise
    try {
      const parsed = Math.floor(Number.parseFloat(slippageInput) * 100)
      if (parsed > 5000) {
        // setSlippageError(SlippageError.InvalidInput);
      } else {
        setUserSlippageTolerance(new Percent(parsed, 10_000))
      }
    } catch (e) {
      // setSlippageError(SlippageError.InvalidInput);
    } finally {
      setIsVisible((v) => !v)
    }
  }, [slippageInput, activeTab])

  return (
    <ModalCenter isVisible={isVisible} setIsVisible={setIsVisible} className="bg-[#121212]">
      <div>
        <div className="flex flex-1 items-center justify-between mb-[24px]">
          <h4 className="text-[20px] leading-[28px] tracking-[-0.4px] text-white">Slippage Settings</h4>

          <button onClick={() => setIsVisible((v) => !v)}>
            <Close />
          </button>
        </div>

        <div className="flex items-center gap-[8px] mb-[16px]">
          {data.map((item, idx) => {
            return (
              <button
                onClick={toggleSlippage(item, idx)}
                key={idx}
                className={clsx(
                  'min-w-[82px] rounded-full text-[16px] leading-[24px] capitalize tracking-[-0.32px] border px-[20px] h-[48px]',
                  {
                    'border-[#1C1C1C]': activeTab !== idx,
                    'border-white': activeTab === idx,
                  }
                )}
              >
                {item.title}
              </button>
            )
          })}
        </div>

        <div className="relative flex items-center mb-[24px] ">
          <label
            htmlFor="custom"
            className="absolute cursor-pointer left-[24px] text-[16px] leading-[24px] tracking-[-0.32px]"
          >
            Custom
          </label>

          <input
            value={slippageInput}
            id="custom"
            placeholder="0,00%"
            onChange={(e) => {
              parseSlippageInput(e.target.value)
            }}
            className="w-full px-[24px] py-[12px] pl-[80px] text-end rounded-full bg-[#1C1C1C] outline-none text-white placeholder-[#808080] text-[16px] leading-[24px] tracking-[-0.32px]"
          />
        </div>

        {warning.show && <p className="mb-[12px] text-[#EB5252]">{warning.title}</p>}

        <ButtonPrimaryV2 onClick={saveSlippage}>
          <span>Save settings</span>
        </ButtonPrimaryV2>
      </div>
    </ModalCenter>
  )
}
