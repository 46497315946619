import { motion } from 'framer-motion'

export function AnimatedSuccess() {
  return (
    <div className="translate-y-[50px]">
      <svg xmlns="http://www.w3.org/2000/svg" width="80" height="160" viewBox="0 0 80 160" fill="none">
        <g clipPath="url(#clip0_6355_9603)">
          <path
            d="M80 160L80 40C80 17.9086 62.0914 7.8281e-07 40 1.74846e-06C17.9086 2.7141e-06 1.41838e-06 17.9086 2.38403e-06 40L7.62939e-06 160L80 160Z"
            fill="url(#paint0_linear_6355_9603)"
            fillOpacity="0.4"
          />
          <motion.path
            transition={{ type: 'spring', stiffness: 50 }}
            initial={{ y: 80 }}
            animate={{ y: 0 }}
            d="M40 0C62.0912 0 80 17.9086 80 40C80 62.0912 62.0912 80 40 80C17.9086 80 0 62.0912 0 40C0 17.9086 17.9086 0 40 0ZM54.1416 25.5254L34.3424 45.3244L25.8572 36.8392C24.2952 35.2772 21.7625 35.2772 20.2004 36.8392C18.6383 38.4012 18.6383 40.934 20.2004 42.496L31.2313 53.5268C32.9496 55.2452 35.7356 55.2452 37.454 53.5268L59.7984 31.1823C61.3604 29.6202 61.3604 27.0875 59.7984 25.5254C58.2364 23.9633 55.7036 23.9633 54.1416 25.5254Z"
            fill="#B7FF5C"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_6355_9603"
            x1="39.2727"
            y1="160"
            x2="39.2727"
            y2="3.03313e-06"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#B7FF5C" stopOpacity="0" />
            <stop offset="1" stopColor="#B7FF5C" />
          </linearGradient>
          <clipPath id="clip0_6355_9603">
            <path
              d="M80 160L80 40C80 17.9086 62.0914 7.8281e-07 40 1.74846e-06C17.9086 2.7141e-06 1.41838e-06 17.9086 2.38403e-06 40L7.62939e-06 160L80 160Z"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>
    </div>
  )
}
