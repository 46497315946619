export function formatDisplay(
  value: number,
  opts: Partial<{
    disableSmallValue?: boolean;
    decimalToShow: number;
    locale: string;
    minimumDecimalToShow?: number;
    disable: boolean;
  }>
) {
  const decimalToShow = opts.decimalToShow ?? 5;
  const formatter = new Intl.NumberFormat(opts.locale || 'en-US', {
    maximumFractionDigits: decimalToShow,
    minimumFractionDigits: opts?.minimumDecimalToShow ?? 0,
  });

  if (opts.disable) {
    return formatter.format(value);
  }

  if (!opts.disableSmallValue && value > 0 && value < Math.pow(10, -decimalToShow)) {
    return `< ${formatter.format(Math.pow(10, -decimalToShow))}`;
  }

  return formatter.format(value);
}
