import { gql, useQuery } from '@apollo/client'
import { apolloClient } from 'graphql/thegraph/apollo'
import { useMemo } from 'react'

const GET_DOGS = gql`
  query Pools($timestamp: Int!, $sort: String!) {
    pools(orderBy: totalValueLockedUSD, orderDirection: $sort) {
      id
      token0 {
        id
        symbol
        decimals
      }
      token1 {
        id
        symbol
        decimals
      }
      totalValueLockedUSD
      volumeUSD
      poolHourData(where: { periodStartUnix_gt: $timestamp }) {
        periodStartUnix
        volumeUSD
      }
    }
  }
`

const GET_DOC_2 = gql`
  query Pools {
    pools(orderDirection: desc) {
      id
      token0 {
        id
        symbol
        decimals
      }
      token1 {
        id
        symbol
        decimals
      }
    }
  }
`

export interface PoolResponse {
  id: string
  totalValueLockedUSD: string
  volumeUSD: string
  token0: {
    id: string
    symbol: string
    decimals: string
  }
  token1: {
    id: string
    symbol: string
    decimals: string
  }
  feesUSD: string
  poolHourData: {
    periodStartUnix: number
    volumeUSD: string
  }[]
}

interface AllPoolResponse {
  pools: PoolResponse[]
}

export function useQueryAllPool(sort: 'asc' | 'desc') {
  const sevenday = useMemo(() => {
    return Math.floor(Date.now() / 1000) - 7 * 24 * 60 * 60
  }, [])

  const data = useQuery<AllPoolResponse>(GET_DOGS, {
    client: apolloClient,
    variables: {
      timestamp: sevenday,
      sort: sort,
    },
  })

  return data
}

export function useAllPoolId() {
  const data = useQuery<AllPoolResponse>(GET_DOC_2, {
    client: apolloClient,
  })

  return data
}
