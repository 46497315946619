import { HelpCircle } from 'react-feather'
import Tooltip from '../Tooltip'
import { useCallback, useState } from 'react'
import { Placement } from '@popperjs/core'

export function QuestionHelperV2({ text, placement }: { text: string; placement?: Placement }) {
  const [show, setShow] = useState<boolean>(false)

  const open = useCallback(() => setShow(true), [setShow])
  const close = useCallback(() => setShow(false), [setShow])

  return (
    <div className="flex">
      <Tooltip placement={placement} text={text} show={show}>
        <div onClick={open} onMouseEnter={open} onMouseLeave={close}>
          <HelpCircle size={16} />
        </div>
      </Tooltip>
    </div>
  )
}
