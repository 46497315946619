import { Trans } from '@lingui/macro'
import { useWeb3React } from '@web3-react/core'
import { useShowMoonpayText } from 'components/AccountDrawer/MiniPortfolio/hooks'
import Column from 'components/Column'
import Row from 'components/Row'
import { networkConnection } from 'connection'
import { ActivationStatus, useActivationState } from 'connection/activate'
import { isSupportedChain } from 'constants/chains'
import { useEffect } from 'react'
import styled from 'styled-components'
import { ThemedText } from 'theme/components'
import { flexColumnNoWrap } from 'theme/styles'
import ConnectionErrorView from './ConnectionErrorView'
import { DeprecatedInjectorMessage } from './Option'
import { useOrderedConnections } from './useOrderedConnections'
import { HoldstationWallet } from './HoldstationWallet'

const Wrapper = styled.div`
  ${flexColumnNoWrap};
  background-color: ${({ theme }) => theme.surface1};
  width: 100%;
  padding: 14px 16px 16px;
  flex: 1;
`

const OptionGrid = styled.div`
  display: grid;
  flex: 1;
  grid-gap: 2px;
  border-radius: 12px;
  overflow: hidden;
  ${({ theme }) => theme.deprecated_mediaWidth.deprecated_upToMedium`
    grid-template-columns: 1fr;
  `};
`

const TextSectionWrapper = styled.div`
  padding: 0 4px;
`

const Line = styled.hr`
  width: 100%;
  border-color: ${({ theme }) => theme.surface3};
`

export default function WalletModal({ openSettings }: { openSettings: () => void }) {
  const { connector, chainId } = useWeb3React()
  const showMoonpayText = useShowMoonpayText()

  const { activationState } = useActivationState()
  // Keep the network connector in sync with any active user connector to prevent chain-switching on wallet disconnection.
  useEffect(() => {
    if (chainId && isSupportedChain(chainId) && connector !== networkConnection.connector) {
      networkConnection.connector.activate(chainId)
    }
  }, [chainId, connector])

  const { orderedConnections, showDeprecatedMessage } = useOrderedConnections()

  return (
    <div data-testid="wallet-modal" className="rounded-[24px] p-[20px] px-[24px] bg-black">
      <div className="mb-[32px]">
        <p className="text-lg text-[#808080]">Connect a wallet</p>
      </div>

      {activationState.status === ActivationStatus.ERROR ? (
        <ConnectionErrorView />
      ) : (
        <Column gap="md" flex="1">
          <HoldstationWallet />

          <div className="w-full h-[1px] bg-[#141414] mt-[16px] mb-2" />

          <p className="text-[#4C4C4C] text-sm">Other wallet</p>

          <Row flex="1" align="flex-start">
            <OptionGrid data-testid="option-grid">{orderedConnections}</OptionGrid>
          </Row>
          {showDeprecatedMessage && (
            <TextSectionWrapper>
              <DeprecatedInjectorMessage />
            </TextSectionWrapper>
          )}
          <Column gap="md">
            {showMoonpayText && (
              <>
                <Line />
                <TextSectionWrapper>
                  <ThemedText.Caption color="neutral3">
                    <Trans>Fiat onramp powered by MoonPay USA LLC</Trans>
                  </ThemedText.Caption>
                </TextSectionWrapper>
              </>
            )}
          </Column>
        </Column>
      )}
    </div>
  )
}
