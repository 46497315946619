import { Trans } from '@lingui/macro'
import { Close } from 'components/Icon/Icon'
import Row from 'components/Row'
import { X } from 'react-feather'
import styled from 'styled-components'
import { ClickableStyle, ThemedText } from 'theme/components'
import { FadePresence } from 'theme/components/FadePresence'
import { ConfirmModalState } from '.'
import { useMemo } from 'react'

// import GetHelpButton from '../Button/GetHelp'

const CloseIcon = styled(X)<{ onClick: () => void }>`
  color: ${({ theme }) => theme.neutral1};
  cursor: pointer;
  ${ClickableStyle}
`

export function SwapHead({
  onDismiss,
  isLimitTrade,
  status,
}: {
  onDismiss: () => void
  isLimitTrade: boolean
  status?: {
    showPreview?: boolean
    showError?: boolean
    showConfirming?: boolean
    showSuccess?: boolean
  }
}) {
  const text = useMemo(() => {
    if (isLimitTrade) {
      return <Trans>Review limit</Trans>
    }

    if (status?.showPreview) {
      return <Trans>Preview swap</Trans>
    }

    if (status?.showConfirming && !status?.showSuccess) {
      return 'Transaction confirmed'
    }

    if (status?.showSuccess) {
      return ''
    }

    if (status?.showError) {
      return 'Error'
    }

    return ''
  }, [status?.showPreview, isLimitTrade, status?.showError, status?.showConfirming])

  return (
    <div className="flex items-center justify-between">
      <div className="w-6 h-6" />

      <h4 className="text-[20px] leading-[28px] tracking-[-0.4px] text-white whitespace-nowrap">{text}</h4>

      <button onClick={onDismiss} data-testid="confirmation-close-icon">
        <Close />
      </button>
    </div>
  )
}
